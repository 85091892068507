export default class Customer {
  constructor(client) {
    this.client = client;
  }

  getAllCustomers(pageNumber=1, pageSize=1000000) {
    return this.client.get(`/customers_list?page[number]=${pageNumber}&page[size]=${pageSize}`);
  }

  searchCustomer(params, value) {
    return this.client.get(`/merchant/search_customers?${params}=${value}`);
  }

  // getAllCustomers(pageNumber=1, pageSize=1000000) {
  //   return this.client.get(`/customers_list?page[number]=${pageNumber}&page[size]=${pageSize}`);
  // }

  addNewCustomer(customerData) {
    return this.client.post("/add_user_direct", { data: customerData });
  }

  editCustomer(customerData) {
    return this.client.post(`/customers/update_customer/${customerData.id}`, {
      data: customerData,
    });
  }

  deleteCustomer(id) {
    return this.client.post(`/customers/set_delete_flag_to_true/${id}`);
  }

  sendSmsToCustomer(smsData) {
    return this.client.post("/short_message_services", { data: smsData });
  }

  sendSmsToCustomerGroup(id, smsData) {
    return this.client.post(`/customer_groups/${id}/send_group_message`, { data: smsData });
  }

  sendSmsToAllCustomer(smsData) {
    return this.client.post("/short_message_service_campaigns", {
      data: smsData,
    });
  }

  redeemReward(data) {
    return this.client.post(
      `/transactions/redeem_reward/${data.redemption_code}/${data.customer_id}/${data.loyalty_program_id}`,
      { quantity: 0 }
    );
  }

  assignLoyaltyIdToCustomer(data) {
    return this.client.put("/assign_loyalty_id_to_customer", data);
  }

  importCustomers(formData) {
    return this.client.post("/customers/import_customers", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  }

  ////////////////////////////////////////////////////
  createWallet(data){
    return this.client.post("/wallet", data);
  }

  fundWallet(data){
    return this.client.patch("/wallet/fund", data);
  }

  fundWalletOverdraftLimit(data){
    return this.client.patch("/wallet/update_overdraft_limit", data);
  }

  withdrawWallet(data){
    return this.client.patch("/wallet/withdraw", data);
  }

  deleteWallet(data){
    console.log("all dat", data);
    
    return this.client.delete("/wallet", {data});
  }
}
